@import "../../utils/variables";
@import "../../utils/mixins";

.grid-item {
  position: relative;

  .video-item__play {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(151, 158, 169, 0.5);
    border-radius: 0px 12px 0px 12px;
  }

  >a {
    display: flex;
    width: 100%;
    height: fit-content;
    max-height: 200px;
    position: relative;

    >img {
      display: block;
      width: 100%;
      object-fit: cover;
      object-position: center center;
      border-radius: 8px;
      height: auto;

      // @include xs() {
      //   min-height: 220px;
      // }

      // @media only screen and (max-width: 430px) {
      //   min-height: 195px;
      // }

      // @media only screen and (max-width: 390px) {
      //   min-height: 180px;
      // }
    }
  }

  .grid-item__title {
    @include font(16, 21px, 700);
    margin-top: 8px;
    margin-bottom: 4px;

    a {
      color: $blue-main;
      text-decoration: none;
    }
  }

  &__date {
    @include font(12, 16px, 400);
    color: #ADADAD;
    display: block;
  }

  &_big {
    >a {
      max-height: 360px;
    }

    .grid-item__title {
      @include font(24, 30px, 700);
    }
  }

  &__important-text {
    position: relative;
    top: 2px;
    color: $blue-main-border;
    @include font(14, 17px, 500);
  }

  &__date-line {
    display: none;
    align-items: center;

    @include xs() {
      display: flex;
    }

    svg {
      margin-left: 16px;
      margin-right: 12px;
    }
  }
}