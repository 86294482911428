@import "../../utils/variables";
@import "../../utils/mixins";

.btn-news {
  padding: 0;
  border: none;
  width: fit-content;
  color: $main;

  span {
    position: relative;
    top: 2x;
  }

  @include sm {
    border: solid 2px $main;
    width: 100%;
    padding: 12px 0;
    color: $text-color;

    svg path {
      stroke: $text-color;
    }
  }
}