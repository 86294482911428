@import "../../utils/variables";
@import "../../utils/mixins";

.pagination {
  @include center-flex();
  padding-top: 24px;

  &__arrow {
    @include size(24px, 24px);
    @include center-flex();
    cursor: pointer;
    background-color: rgba(45, 45, 45, 0.0);
    transition: background-color 0.2s ease-in-out;
    border-radius: 50%;

    &:hover {
      background-color: rgba(45, 45, 45, 0.05);
    }
  }

  &__page {
    @include center-flex();
    cursor: pointer;
    @include font(24px, 32px, 600);
    color: $neutral-100;
    margin-right: 48px;

    &-dots {
      cursor: auto;
      color: #D2D2D2;
    }

    &_disabled {
      opacity: 0.3;
      cursor: default;

      &:hover {
        background-color: transparent;
      }
    }
  }

  &__current {
    color: $main;
    border: solid 1px $main;
    border-radius: 50%;
    min-width: 48px;
    min-height: 48px;
    margin-right: 40px;
    margin-left: -8px;

    &_no-border {
      border: none;
    }
  }

  &__first {
    margin-right: 48px;
  }

  &__last {
    margin-left: 0;
  }

  &_tags {
    margin-top: 32px;

    @include sm() {
      margin-top: 0;
    }

    .pagination__last {
      margin-left: 4.5px;
    }
  }
}