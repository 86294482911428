@import "../../utils/variables";
@import "../../utils/mixins";

.btn-primary {
  text-decoration: none;
  background-color: $main;
  padding: 12px 16px;
  color: $white;
  @include font(16, 22.4px, 500);
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  transition-property: background-color, color;

  &:hover {
    background-color: $main-hovered;
    color: $main-surface;
  }

  &:active {
    background-color: $main-pressed;
    color: $main-border;
  }

  &:focus-visible {
    outline: 4px solid rgba(255, 117, 52, 0.25);
    border: none;
  }
}