@import "../../utils/variables";
@import "../../utils/mixins";

.column-item {
  background-color: $white;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  &__author {
    display: flex;
    align-items: center;

    >div {
      margin-left: 16px;
    }

    &-title {
      @include font(16, 22.5px, 500);
      color: $neutral-100;
    }

    &-subtitle {
      text-transform: uppercase;
      @include font(12, 16.8px, 400);
      color: $neutral-80;
      display: inline-block;
      margin-top: 4px;
      @include string-cutter(1);
    }
  }

  &__date {
    text-transform: uppercase;
    @include font(12, 16.8px, 400);
    color: $neutral-80;
  }

  &__title {
    a {
      color: $blue-main;
      display: block;
      @include font(16, 20.5px, 600);
      text-decoration: none;
      transition: 0.2s;

      &:hover {
        color: $main;
      }

      @include xs() {
        @include font(20, 26px, 600);
      }
    }

    margin-top: 16px;
    margin-bottom: 12px;
  }
}