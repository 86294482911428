@import "../../utils/variables";
@import "../../utils/mixins";

.desktop-menu-popup {
  width: 100%;
  position: fixed;
  background-color: rgba(24, 40, 64, 0.5);
  z-index: 30;
  top: 90px;
  transition: 0.15s ease-in-out;
  //height: 100vh;
  opacity: 0;

  &__socials {
    display: flex;
    padding: 16px 0;
    margin-top: 24px;

    a {
      text-decoration: none;
    }

    p,
    a {
      @include font(12, 17px, 400);
    }

    &-title {
      color: $neutral-60;
      margin-right: 24px;
    }

    &-list {
      display: flex;

      &-item {
        margin-right: 24px;

        a {
          color: $neutral-80;
        }
      }
    }
  }

  overflow-y: scroll;
  //make hidden scroll
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  bottom: 0px;


  &__wrapper {
    padding-top: 30px;
    background-color: $white;


    > div {
      width: 100%;
      flex-direction: row;
    }
  }

  &__list {
    display: block;
    width: fit-content;
    margin-right: 56px;


    &:first-of-type {
      margin-right: 61px;
    }

    a {
      display: block;
      text-decoration: none;
    }

    &-item {
      margin-bottom: 1.1em;
      display: block;

      a {
        @include font(16, 20.5px, 600);
        text-decoration: none;
        color: $blue-main;
      }
    }

    > .desktop-menu-popup__nested-list:last-child {
      margin-bottom: 0;
    }

    &-item_small {
      margin-bottom: 7px;
      display: block;

      a {
        color: $neutral-90;
        white-space: nowrap;
        @include font(14, 19.6px, 400);
      }
    }
  }

  &__nested-list {
    margin-top: -8px;
    margin-bottom: 1.4em;
  }
}

