@import "../../utils/variables";
@import "../../utils/mixins";

.page-header {
  &.container {
    width: 100%;
  }

  @include sm() {
    background-color: $white;

    .breadcrumbs {
      padding-left: 0;
    }
  }

  &__title {
    color: $neutral-100;
    @include font(32, 40px, 700);

    @include sm {
      @include font(24px, 30px);
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}