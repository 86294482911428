@import "../../utils/variables";
@import "../../utils/mixins";

.btn {
  border-radius: 32px;
  border: solid 2px $main;
  padding: 12px 0;
  background-color: transparent;
  cursor: pointer;
  @include center-flex();
  max-width: 304px;
  width: 100%;
  @include font(16, 24px, 600);
  max-height: 48px;
  color: $text-color;
  display: flex;
  align-items: center;
  transition: background-color 0.2s;

  &:hover:not(.btn-news) {
    background-color: #D5D8DD;

    @include sm() {
      background-color: transparent;
    }
  }

  &:active:not(.btn-news) {
    background-color: $blue-main-border;
  }

  &:disabled:not(.btn-news) {
    border: 1px solid $blue-main-border;
    color: $blue-main-border;
  }

  @include sm() {
    max-width: 100%;
  }

  svg,
  img {
    margin-left: 8px;
  }

  &_light {
    color: $white;

    @include sm() {
      margin: 0;
      margin-top: 4px;
      color: $main;
    }

    >div {
      @include sm() {
        display: none;
      }
    }
  }
}