@import "../../utils/variables";
@import "../../utils/mixins";

.dropdown-content {
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease-in-out;
  transition-property: opacity, visibility;
  width: 100%;
  position: absolute;
  z-index: 100;
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.08)) drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.06)) drop-shadow(0px 1px 14px rgba(0, 0, 0, 0.04));
  margin-top: 12px;

  &_active {
    opacity: 1;
    visibility: visible;
  }

  &-categories {
    background-color: $white;
    border-radius: 24px;
    max-height: 336px;
    overflow: auto;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;

  &__btn {
    background-color: transparent;
    height: 48px;
    width: 100%;
    border-radius: 32px;
    text-align: left;
    padding: 0;
    padding-left: 16px;
    box-sizing: border-box;
    @include font(16px, 24px, 400);
    color: $neutral-80;
    border: solid 1.5px rgba(210, 210, 210, 0.5);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    span {
      transition: color 0.2s linear;
    }

    &_active {
      span {
        color: $neutral-100;
      }

      .dropdown__icon_arrow {
        transform: rotate(180deg);
      }
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__icon {
    margin-right: 16px;
    transition: transform 0.2s linear;
  }

  &__item {
    display: block;
    cursor: pointer;
    padding: 12px 16px;
    @include font(16px, 24px, 500);
    color: $neutral-100;
    text-decoration: none;
    border-bottom: solid 1.5px rgba(210, 210, 210, 0.5);
    position: relative;
    transition: background-color 0.2s linear;

    &::before {
      content: url("/src/images/icons/check.svg");
      position: absolute;
      width: fit-content;
      right: 16px;
      top: 14px;
      opacity: 0;
      transition: opacity 0.2s linear;
    }

    &:last-child {
      border-bottom: none;
    }

    &_selected {
      background-color: #FEF4EF;

      &::before {
        opacity: 1;
      }
    }
  }

  &__label {
    @include font(12px, 16.5px, 400);
    margin-bottom: 8px;
    display: block;
    color: #ADADAD;
  }
}