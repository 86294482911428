@import "../../utils/variables";
@import "../../utils/mixins";

.datepicker {
  width: 100%;
  position: relative;
  min-width: 304px;
  max-width: 304px;

  .air-datepicker--buttons {
    border-top: none;
  }

  .air-datepicker-button {
    @include font(16, 19.6px, 600);
    color: $main;
  }

  //&__today {
  //  position: absolute;
  //  width: 100%;
  //  @include font(14, 19.6px, 600);
  //  color: $main;
  //  bottom: 20px;
  //  text-align: center;
  //  cursor: pointer;
  //}

  &__btn {
    width: 100%;
    text-align: center;
    display: block;
    border-radius: 4px;
    max-height: 45px;
    border: none;
  }

  .air-datepicker {
    width: calc(100% - 32px);
    border-radius: 16px;
    border: none;
    padding: 15px 12px 15px 8px;
    font-family: $font-primary;
  }

  .air-datepicker-nav--action svg {
    @include size(20px, 20px);
  }

  .air-datepicker-nav--action path {
    stroke: $neutral-100;
  }

  .air-datepicker-nav--title {
    color: $neutral-100;
    @include font(16px, 21px, 700);

    i {
      color: inherit;
    }
  }

  .air-datepicker--content {
    padding: 0;
  }

  .air-datepicker-body--day-names {
    margin: 0;
    margin-top: 8px;
    @include font(16px, 20px, 600);
  }

  .air-datepicker-body--day-name {
    @include size(40px, 36px);
    text-align: center;
  }

  .air-datepicker-cell {
    color: $neutral-100;
    @include font(16, 20px, 600);
    @include size(33px, 33px);
    border-radius: 100%;

    &.-current- {
      color: $neutral-100;
    }

    &.-selected- {
      background-color: $main !important;
      color: $white;
    }

    &.-focus- {
      background-color: $main !important;
      color: $white !important;
    }

    // &.-day- {
    //   // max-width: 40px;
    //   // padding-top: 8px;
    // }

    &.-other-month- {
      color: $neutral-70;
    }

    &.-in-range- {
      background: $neutral-20;
      color: $neutral-100;
    }
  }

  .air-datepicker-body--day-name {
    color: $neutral-100;
    @include font(16px, 20px, 600);
  }

  .air-datepicker-nav {
    border-bottom: none;
    padding: 0;
    height: 21px;
  }

  .air-datepicker-body--cells.-days- {
    grid-auto-rows: auto !important;
  }
}

#filter-button {
  cursor: pointer;
}